// author:马云阳；description:众惠-公募-填写转换信息2
<template>
    <section class="PublicFundProcess ">
        <site-nav>
          <span>&gt;</span>
          <RouterLink :to="{name:'FundMarket'}">基金超市</RouterLink>
          <span>&gt;</span>
          <span>{{funddatas.funddata.fundName}}</span>
        </site-nav>
        <!-- <NavRightSide/> -->
        <div class="publicfundprocess_cont fixed_width">
            <a @click="topeges" class="title_">
             <span class="iconfont">&#xe665;</span> {{funddatas.funddata.fundName}}
            </a>
            <div class="side_cont">
              <ProgressBar :sideData="side"/>
            </div>
            <div class="solids_"></div>
           <div class="card_">
              <img src="@/static/img/my/PasswordAlterSuccess.png" class="true_" alt="">
              <div class="true_ti">
                <div class="tis_">申请受理成功<br/>本交易支持{{txts}}前撤回</div>
                <div class="timers_">{{newdates}}</div>
              </div>
              <div class="cards_tis">
                <span>定投计划预计将于{{xq}}24:00生效</span>
                <span>根据转换最终确认结果，会出现多余资金退回银行卡/惠金宝，不足资金从银行卡/惠金宝扣除的情况，请关注通知</span>
              </div>
              <div class="card_timers">
                <div class="l_ti">定投基金</div>
                <div class="r_timers">{{funddatas.funddata.fundName}}</div>
              </div>
              <div class="card_timers">
                <div class="l_ti">买入金额</div>
                <div class="r_timers">{{funddatas.balance}}元</div>
              </div>
              <div class="card_timers">
                <div class="l_ti">转出方式</div>
                <div class="r_timers" v-if="funddatas.payType=='1'">惠金宝</div>
                <div class="r_timers" v-else style="color:#25293D;font-weight:400;">{{funddatas.banklist.bankName}}<span style="color:#A5A5A5;">({{banknums}})</span></div>
              </div>
              <div class="card_timers_">
                <div class="l_ti">下次扣款</div>
                <div class="r_timers">
                  <div style=" align-items: unset;" class="top_">
                    <div class="imgs_ img_rudis">
                      <!-- <img src="" alt=""> -->
                    </div>
                    <div class="top_name_">
                      {{xq}}<br/><span style="font-size: 18px;color: #25293d;font-weight: 300;">下次扣款金额{{funddatas.balance}}元</span>
                      <!-- <span>161725</span> -->
                    </div>
                  </div>
                  <!-- <div class="center_">
                     <div class="imgs_">
                      <span style="color:#CE9B63" class="iconfont icon-a-mine-roolout"></span>
                    </div>
                  </div> -->
                </div>
              </div>
              <a @click="topages" class="topage_">
                查看我的定投
              </a>
          </div>
          
        </div>
    </section>
</template>
<script>
// import NavRightSide from "@/components/NavRightSide";
import ProgressBar from "@/components/ProgressBar";
import SiteNav from '@/views/fund-market/components/SiteNav.vue'

export default {
  name: "AddTrueFour",
  components: { ProgressBar ,SiteNav},
  data() {
    return {
      side: [
        { title: "填写定投信息", type: 1 },
        { title: "输入交易密码", type: 1 },
        { title: "操作结果", type: 2 }
      ],
      funddatas: {},
      newdates: "--",
      txts: "--",
      xq: "--",
      banknums: null //
    };
  },
  methods: {
    topages() {
      this.$router.push({name:"FundSetFing"})
    },

    getnewdata(a) {
      let date = a;
      let yy = date.getFullYear();
      let mm = date.getMonth() + 1;
      let dd = date.getDate();
      let hh = date.getHours();
      let mf =
        date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
      let ss =
        date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      let jjj = yy + "-" + mm + "-" + dd + " " + "15:00:00";
      let aaa = new Date(jjj);
      this.newdates = yy + "-" + mm + "-" + dd + " " + hh + ":" + mf + ":" + ss;
      let bbb = new Date(this.newdates);
      if (bbb >= aaa) {
        this.txts = "明天15点";
        let dds = date.getDate() + 2;
        let ees = date.getDate() + 3;
        this.Ns = yy + "-" + mm + "-" + dds;
        this.Es = yy + "-" + mm + "-" + ees;
      } else {
        this.txts = "15点";
        let dds = date.getDate() + 1;
        let ees = date.getDate() + 2;
        this.Ns = yy + "-" + mm + "-" + dds;
        this.Es = yy + "-" + mm + "-" + ees;
      }
    },
    getweed(a) {
      console.log("a", a);
      let yy = a.getFullYear();
      let mm = a.getMonth() + 1;
      let dd = a.getDate();
 
      let jjj = yy + "-" + mm + "-" + dd;
      let weekDay = [
        "星期天",
        "星期一",
        "星期二",
        "星期三",
        "星期四",
        "星期五",
        "星期六"
      ];
      this.xq =jjj+' '+ weekDay[a.getDay()];
    },
        topeges() {
      this.$router.go(-3)
    },
    
  },
  created() {
    this.funddatas = this.$route.params.okey;
    this.banknums = this.funddatas.banklist.bankAccount.slice(-4);
    console.log("this.funddatas", this.funddatas);
    this.getnewdata(this.funddatas.dates);
    this.getweed(this.funddatas.newdate);
  }
};
</script>
<style lang="less" scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
input[type="number"] {
  -moz-appearance: textfield;
}
::-webkit-input-placeholder {
  color: #bdc0cb;
}
::-moz-placeholder {
  color: #bdc0cb;
}

/* firefox 19+ */
:-ms-input-placeholder {
  color: #bdc0cb;
}

/* ie */
input:-moz-placeholder {
  color: #bdc0cb;
}

.PublicFundProcess {
  padding: 0px 0px 0px 0;
  min-height: 600px;
  // display: flex;
  background: #F3F4F6;
  .publicfundprocess_cont {
    background: #fff;
    min-height: 1290px;
    width: 1200px;
    .title_ {
      display: inline-block;
      padding: 40px 40px 0px 40px;
      height: 60px;
      font-size: 20px;
      color: #1f1f1f;
    }
    .side_cont {
      height: 146px;
      padding: 0px 0px 0px 80px;
      display: flex;
      align-items: center;
    }
    .solids_ {
      height: 2px;
      background: linear-gradient(270deg, #eabf96 0%, #f7d9b7);
      box-shadow: 0px 4px 12px 0px #edf1f7;
    }
    .card_ {
      margin-top: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      .true_ {
        width: 120px;
        height: 120px;
        margin-bottom: 30px;
      }
      .true_ti {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-bottom: 20px;
        .tis_ {
          font-size: 20px;
          font-family: Microsoft YaHei UI, Microsoft YaHei UI-Regular;
          font-weight: 400;
          color: #25293d;
          text-align: center;
        }
        .timers_ {
          font-size: 16px;
          font-family: Microsoft YaHei UI, Microsoft YaHei UI-Regular;
          font-weight: 400;
          color: #a5a5a5;
          margin-top: 14px;
        }
      }
      .cards_tis {
        width: 646px;
        height: 64px;
        background: rgba(255, 245, 234, 0.5);
        border-radius: 2px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
        span {
          font-size: 12px;
          font-family: Microsoft YaHei UI, Microsoft YaHei UI-Light;
          font-weight: 300;
          color: #ce9b63;
          text-align: center;
        }
      }
      .card_timers {
        margin-top: 20px;
        display: flex;
        width: 100%;
        align-items: center;
        .l_ti {
          width: 42%;
          margin-right: 2%;
          font-size: 16px;
          font-family: Microsoft YaHei UI, Microsoft YaHei UI-Regular;
          font-weight: 400;
          text-align: RIGHT;
          color: #a5a5a5;
        }
        .r_timers {
          font-size: 18px;
          font-family: Microsoft YaHei UI, Microsoft YaHei UI-Light;
          font-weight: 300;
          text-align: left;
          color: #25293d;
          display: flex;
          align-items: center;
          img {
            margin-right: 8px;
          }
        }
      }
      .card_timers_ {
        margin-top: 24px;
        display: flex;
        width: 100%;
        .l_ti {
          width: 42%;
          margin-right: 2%;
          font-size: 16px;
          font-family: Microsoft YaHei UI, Microsoft YaHei UI-Regular;
          font-weight: 400;
          text-align: RIGHT;
          color: #a5a5a5;
        }
        .r_timers {
          .top_ {
            display: flex;
            align-items: center;
            // .imgs_ {
            //   width: 28px;
            //   height: 28px;
            //   img {
            //     width: 100%;
            //     height: 100%;
            //   }
            // }
            .img_rudis {
              border: 2px solid #eabf96;
              // border-image: linear-gradient(134deg, #f7d9b7 0%, #eabf96 100%)
              //   1.6399157047271729 1.6399157047271729;
              border-radius: 50% !important;
              width: 16px !important;
              height: 16px !important;
            }
            .top_name_ {
              font-size: 18px;
              font-family: Microsoft YaHei UI, Microsoft YaHei UI-Regular;
              font-weight: 400;
              color: #25293d;
              margin-left: 8px;
              span {
                color: #a5a5a5;
              }
            }
          }
          .center_ {
            display: flex;
            margin-top: 30px;
            // align-items: center;
            .imgs_ {
              width: 28px;
              height: 28px;
              text-align: center;
              img {
                width: 7px;
                height: 25px;
              }
            }
            .top_name_ {
              font-size: 16px;
              font-family: Microsoft YaHei UI, Microsoft YaHei UI-Regular;
              font-weight: 400;
              color: #a5a5a5;
              margin-left: 8px;
              span {
                font-size: 18px;
                font-family: Microsoft YaHei UI, Microsoft YaHei UI-Regular;
                font-weight: 400;
                color: #e45247;
              }
            }
          }
          .footer_ {
            display: flex;
            align-items: center;
            margin-top: 30px;
            .imgs_ {
              width: 28px;
              height: 28px;
              img {
                width: 100%;
                height: 100%;
              }
            }
            .img_rudis {
              border: 2px solid #eabf96;
              // border-image: linear-gradient(134deg, #f7d9b7 0%, #eabf96 100%)
              //   1.6399157047271729 1.6399157047271729;
              border-radius: 50% !important;
              width: 16px !important;
              height: 16px !important;
            }
            .top_name_ {
              font-size: 18px;
              font-family: Microsoft YaHei UI, Microsoft YaHei UI-Regular;
              font-weight: 400;
              color: #25293d;
              margin-left: 8px;
              span {
                color: #a5a5a5;
              }
            }
          }
        }
      }
      .topage_ {
        display: inline-block;
        width: 240px;
        height: 48px;
        background: linear-gradient(101deg, #f7d9b7 0%, #eabf96 100%);
        border-radius: 2px;
        text-align: center;
        line-height: 48px;
        font-size: 16px;
        font-family: PingFang SC, PingFang SC-Regular;
        font-weight: 400;
        color: #b88141;
        margin-top: 60px;
      }
    }
  }
}
</style>
